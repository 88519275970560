import React from 'react'
import { graphql } from 'gatsby'
import Grid from '@material-ui/core/Grid';

class PlansContainer extends React.Component {  
    constructor(props) {
        super(props);        
    }
    
    render() {
        let inactiveWidth = 3;
        let activeWidth = 6
        var pricing = <div className="planspricefreq" >per Month.</div>
        var freq = 'monthly';
        if(this.props.annualpricing) { pricing = (<div className="planspricefreq" >per Year <br/> <span className="planspricefreq">(20% discount)</span></div>); freq = 'yearly'; }
        else if (this.props.plandetails.type !== 'subscription') { pricing = <div className="planspricefreq" >one off.</div>; freq = 'onetime'; }

        var subscriptionName = this.props.plandetails.pricedetails.monthly_base.product.name;
        var subscriptionid = this.props.plandetails.pricedetails.monthly_base.id;
        var subscriptionprice = this.props.plandetails.pricedetails.monthly_base.unit_amount;
        if(this.props.annualpricing)
        {
            subscriptionName = this.props.plandetails.pricedetails.yearly_base.product.name;
            subscriptionid = this.props.plandetails.pricedetails.yearly_base.id;
            subscriptionprice = this.props.plandetails.pricedetails.yearly_base.unit_amount;
        }
        const planscontainer_active = (
            <Grid item xs={activeWidth} className="plans_container"  >
                <Grid container spacing={0}>
                    <Grid item xs={12} className="plans_gridbox">
                        <Grid container spacing={0} style={{height:"100%"}}>
                            <Grid item xs={12} >
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <div className="plansheading">
                                            <h2>{subscriptionName}</h2>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0} >
                                    <Grid item xs={8}>
                                        <div className="plansdetail">
                                            <ul className="planslist">
                                                {this.props.plandetails.planfeatures.map(item => {
                                                    return (
                                                        <li key={item} className="planslistitem">{item}</li>    
                                                    )
                                                })}                       
                                            </ul>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Grid container spacing={0} >
                                            <Grid item xs={12}>
                                                <input style={{visibility: 'hidden'}} readOnly name="priceSelect" value={subscriptionid} />
                                                <div className="planspricecontainer" >
                                                    ${((subscriptionprice/100)).toFixed(0)}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {pricing}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={0} >
                                            <Grid item xs={1} md={6} lg={8} />
                                            <Grid item xs={10} md={6} lg={4} style={{display:"flex", alignItems:"center", justifyContent:"center"}} >
                                                <div className="planspricebutton" >
                                                    <div className="plans_button_main" role="button" onClick={() => {this.props.selectPlan(subscriptionid, this.props.plandetails, freq)}}>
                                                        Signup Now
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>                                
                                    </Grid>
                                </Grid>                            
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>                        
            </Grid>);
        const planscontainer_inactive = (
            <Grid item xs={inactiveWidth} className="plans_container" onClick={() => {this.props.activeItemAdj(this.props.plandetails.planName)}}>
                <Grid container spacing={0}>
                    <Grid item xs={12} className="plans_gridbox">
                        <Grid container spacing={0} style={{height:"100%"}}>
                            <Grid item xs={12} >
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <div className="plansheading">
                                            <h2>{subscriptionName}</h2>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0}>                                            
                                    <Grid item xs={12}>
                                        <Grid container spacing={0} >
                                            <Grid item xs={12}>
                                                <input style={{visibility: 'hidden'}} readOnly name="priceSelect" value={subscriptionid} />
                                                <div className="planspricecontainer"  >
                                                    ${((subscriptionprice/100)).toFixed(0)}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {pricing}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={0} >                                            
                                            <Grid item xs={2} md={6} lg={7} />
                                            <Grid item xs={12} md={4} lg={4} style={{display:"flex", alignItems:"center", justifyContent:"center"}} >
                                                <div className="planspricebutton" >
                                                    <div className="plans_button_main" role="button" onClick={() => {this.props.activeItemAdj(this.props.plandetails)}}>
                                                        Read More
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>                                
                                    </Grid>
                                </Grid>                            
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>);
        const planscontainer_mob = (
            <Grid item xs={12} className="plans_container"  >
                <Grid container spacing={0}>
                    <Grid item xs={12} className="plans_gridbox">
                        <Grid container spacing={0} style={{height:"100%"}}>
                            <Grid item xs={12} >
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <div className="plansheading">
                                            <h2>{subscriptionName}</h2>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0} >
                                    <Grid item xs={8}>
                                        <div className="plansdetail">
                                            <ul className="planslist">
                                                {this.props.plandetails.planfeatures.map(item => {
                                                    return (
                                                        <li key={item} className="planslistitem">{item}</li>    
                                                    )
                                                })}                       
                                            </ul>
                                        </div>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Grid container spacing={0} >
                                            <Grid item xs={12}>
                                                <input style={{visibility: 'hidden'}} readOnly name="priceSelect" value={subscriptionid} />
                                                <div className="planspricecontainer" >
                                                    ${((subscriptionprice/100)).toFixed(0)}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12}>
                                                {pricing}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={0}>
                                    <Grid item xs={12}>
                                        <Grid container spacing={0} >
                                            <Grid item xs={1} md={6} lg={8} />
                                            <Grid item xs={10} md={6} lg={4} style={{display:"flex", alignItems:"center", justifyContent:"center"}} >
                                                <div className="planspricebutton" >
                                                    <div className="plans_button_main" role="button" onClick={() => {this.props.selectPlan(subscriptionid, this.props.plandetails, freq)}}>
                                                        Signup Now
                                                    </div>
                                                </div>
                                            </Grid>
                                        </Grid>                                
                                    </Grid>
                                </Grid>                            
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>                        
            </Grid>);
        
        if(this.props.pagewidth) {
            return (
                <React.Fragment>
                    {planscontainer_mob}
                </React.Fragment>   
            )
        }
        else {
            if(this.props.activeItem === this.props.plandetails.planName)
            {
                return (
                    <React.Fragment>
                        {planscontainer_active}
                    </React.Fragment>   
                )
            }
            else 
            {
                return (
                    <React.Fragment>
                        {planscontainer_inactive}
                    </React.Fragment>   
                )
            }         
        }
    }
}

export default PlansContainer
export const pageQuery = graphql`
  query PlansContainerQuery {
    site {
      siteMetadata {
        title
      }
    }        
  }
`
