import * as React from 'react'
import { graphql, navigate } from 'gatsby'
import get from 'lodash/get'
import { instanceOf } from 'prop-types'
import { withCookies, Cookies } from 'react-cookie'
import Grid from '@material-ui/core/Grid'
import { AiOutlineClose } from 'react-icons/ai'
import { styled } from '@mui/material/styles'
import FormHelperText from '@mui/material/FormHelperText'
import Switch from '@mui/material/Switch'
import Layout from '../components/layout'
import Seo from '../components/seo'
import PlansContainer from '../components/planscontainer'
import plansdata from '../utils/plansdata'
import getStripe from '../utils/stripejs'
import moment from 'moment'

const MOBILE_BREAKPOINT = 721
const isClient = typeof window !== 'undefined'

class PlansPage extends React.Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired,
  }
  constructor(props) {
    super(props)
    this.state = {
      activeItem: 'investor',
      offeroverlay: true,
      loading: false,
      annualprice: false,
      viewportWidth: isClient ? window.innerWidth : 0,
    }
    this.changeCookie = this.changeCookie.bind(this)
    this.activeItemAdj = this.activeItemAdj.bind(this)
    this.selectPlan = this.selectPlan.bind(this)
    this.setLoading = this.setLoading.bind(this)
    this.toggleOverlay = this.toggleOverlay.bind(this)
    this.togglePricing = this.togglePricing.bind(this)
  }
  changeCookie(cookieName, newState) {
    const { cookies } = this.props
    cookies.set(cookieName, newState, { path: '/' })
    this.setState({ cookieName: newState })
  }
  activeItemAdj(itemname) {
    var oldState = this.state
    this.setState({
      ...oldState,
      activeItem: itemname,
    })
  }
  setLoading(loading) {
    this.setState({ loading })
  }
  selectPlan(subscriptionid, subDetails, frequency) {
    this.changeCookie('subscriptiontype', subDetails.planName)
    this.changeCookie('subscriptionid', subscriptionid)
    this.changeCookie('planDetails', subDetails)
    this.changeCookie('frequency', frequency)
    

    navigate(`/signup`)

    // window.location.href = `${window.location.origin}/signup`
  }
  toggleOverlay() {
    var overlaydisplay = !this.state.offeroverlay
    this.setState({
      ...this.state,
      offeroverlay: overlaydisplay,
    })
  }
  togglePricing() {
    var annualpricing = !this.state.annualprice
    this.setState({
      ...this.state,
      annualprice: annualpricing,
    })
  }
  updateWindowDimensions = () => {
    this.setState({ viewportWidth: window.innerWidth })
  }
  componentDidMount() {
    this.changeCookie('lastpage', '/plans')
    if (!this.props.allCookies.firstload) {            
      setTimeout(() => {
        navigate('/loading')
      }, 500)
    }
    if (isClient) {
      this.updateWindowDimensions()
      window.addEventListener('resize', this.updateWindowDimensions)
    }
  }
  componentWillUnmount() {
    if (isClient)
      window.removeEventListener('resize', this.updateWindowDimensions)
  }
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const keywords = get(this, 'props.data.site.siteMetadata.keywords')
    const allproducts = get(this, 'props.data.prices.edges')

    var products = []
    var lifetimeplan = {}
    Object.keys(plansdata).map((key) => {
      var details = plansdata[key]

      if (details.planName === 'lifetime') {
        for (var i = 0; i < allproducts.length; i++) {
          if (allproducts[i].node.product.id === details.id) {
            var pricedetails = {
              ...details.pricedetails,
            }
            var pricekey =
              allproducts[i].node.nickname.split('_')[1].toLowerCase() +
              '_' +
              allproducts[i].node.nickname.split('_')[2].toLowerCase()
            pricedetails[pricekey] = allproducts[i].node
            details.pricedetails = pricedetails
            lifetimeplan = details
          }
        }
      } else {
        for (var i = 0; i < allproducts.length; i++) {
          if (allproducts[i].node.product.id === details.id) {
            var pricedetails = {
              ...details.pricedetails,
            }
            var pricekey =
              allproducts[i].node.nickname.split('_')[1].toLowerCase() +
              '_' +
              allproducts[i].node.nickname.split('_')[2].toLowerCase()
            pricedetails[pricekey] = allproducts[i].node
            details.pricedetails = pricedetails
            // products.push(details)
          }
        }
      }
      plansdata[key] = details
    })
    Object.keys(plansdata).map((key) => {
      if (plansdata[key].planName !== 'lifetime') {
        products.push(plansdata[key])
      }
    })
    const pageName = 'Pricing Plans'
    const FreqSwitch = styled(Switch)(({ theme }) => ({
      '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#ffffff',
      },
      '& .MuiSwitch-switchBase': {
        color: '#4648ff',
      },
      '& .MuiSwitch-switchBase + .MuiSwitch-track': {
        backgroundColor: '#4648ff',
      },
    }))
    const { viewportWidth } = this.state
    const isMobile = Boolean(viewportWidth <= MOBILE_BREAKPOINT)
    var pagewidth = {
      matches: false,
      media: '(max-width: 600px)',
      onchange: null,
    }
    if (typeof window !== 'undefined') {
      pagewidth = window.matchMedia('(max-width: 600px)')
    }

    var lifetime_overlay = (
      <div className="plans_overlay " style={{ visibility: 'visible' }}>
        <Grid container spacing={0} style={{ width: '100%' }}>
          <Grid item xs={12}>
            <Grid container spacing={0}>
              <Grid item xs={11} />
              <Grid item xs={1}>
                <div
                  className="plans_overlay_close"
                  role="button"
                  onClick={this.toggleOverlay}
                  onKeyPress={this.toggleOverlay}
                >
                  <AiOutlineClose />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <div className="overlay_container">
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <h2>Pre-Launch Offer</h2>
                    </Grid>
                    <Grid item xs={12}>
                      <p>
                        Sign up now for our Pre-Launch offer to get <em>LIFETIME ACCESS </em> 
                        for the one-off price of <em>$100 USD</em>. Limited to the first 
                        5000 subscribers, you better act fast.
                      </p>
                      <p>
                        As we progress to the official launch of EdgeForecaster, we are 
                        fine tuning the artificial intelligence models, testing the 
                        platform, optimising the data processing, and completing 
                        development of the mobile app.
                      </p>
                      <p>
                        We want to reward those who see the potential in a market tool 
                        like EdgeForecaster and those who want to be an early adopter 
                        of what will soon be essential for anyone wanting to actively 
                        trade. Our Pre-Launch offer gives complete, <em>LIFETIME ACCESS </em> 
                        to the EdgeForecaster platform, including all future development 
                        releases to anyone who signs up over the current period prior 
                        to our official launch. 
                      </p>
                      <p>
                        Click the <em>SignUp Now</em> button to secure your
                        lifetime market <em>EDGE</em>
                      </p>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className="overlay_container">
                  <Grid container spacing={0} style={{ height: '100%' }}>
                    <Grid item xs={12}>
                      <Grid container spacing={0}>
                        <Grid item xs={12}>
                          <div className="plansheading">
                            <h2>
                              {
                                lifetimeplan.pricedetails['onetime_base']
                                  .product.name
                              }
                            </h2>
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container spacing={0}>
                        <Grid item xs={8}>
                          <div className="plansdetail">
                            <ul className="planslist">
                              {lifetimeplan.planfeatures.map((item) => {
                                return (
                                  <li key={item} className="planslistitem">
                                    {item}
                                  </li>
                                )
                              })}
                            </ul>
                          </div>
                        </Grid>
                        <Grid item xs={4}>
                          <Grid container spacing={0}>
                            <Grid item xs={12}>
                              <input
                                style={{ visibility: 'hidden' }}
                                readOnly
                                name="priceSelect"
                                value={lifetimeplan.pricedetails.id}
                              />
                              <div className="planspricecontainer">
                                $
                                {(
                                  lifetimeplan.pricedetails['onetime_base']
                                    .unit_amount / 100
                                ).toFixed(0)}
                              </div>
                            </Grid>
                            <Grid item xs={12}>
                              <div className="planspricefreq">
                                {this.props.type === 'subscription'
                                  ? 'per month'
                                  : 'one off'}
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container spacing={0}>
                        <Grid item xs={12}>
                          <Grid container spacing={0}>
                            <Grid item xs={1} md={6} lg={8} />
                            <Grid item xs={10} md={6} lg={4} style={{display:"flex", alignItems:"center", justifyContent:"center"}} >
                              <div className="planspricebutton">
                                <div
                                  className="plans_button_main"
                                  onClick={() => {
                                    this.selectPlan(
                                      lifetimeplan.pricedetails['onetime_base']
                                        .id,
                                      lifetimeplan,
                                      'onetime',
                                    )
                                  }}
                                >
                                  Signup Now
                                </div>
                              </div>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
    var detailsandswitch = (
      <Grid container spacing={0}>
        <Grid item xs={10}>
          <div className="plans_subheading">
            Plans available for all levels of experience and trading activity:{' '}
          </div>
        </Grid>
        <Grid item xs={2}>
          <Grid container spacing={0}>
            <Grid item xs={2} />
            <Grid item xs={2}>
              <FormHelperText className="priceSwitchLabel">
                monthly
              </FormHelperText>
            </Grid>
            <Grid item xs={3}>
              <FreqSwitch
                checked={this.state.annualprice}
                onChange={this.togglePricing}
              />
            </Grid>
            <Grid item xs={2}>
              <FormHelperText className="priceSwitchLabel">
                annually
              </FormHelperText>
            </Grid>
            <Grid item xs={2} />
          </Grid>
        </Grid>
      </Grid>
    )
    if (isMobile) {
      lifetime_overlay = (
        <div className="plans_overlay " style={{ visibility: 'visible' }}>
          <Grid container spacing={0} style={{ width: '100%' }}>
            <Grid item xs={12}>
              <Grid container spacing={0}>
                <Grid item xs={11} />
                <Grid item xs={1}>
                  <div
                    className="plans_overlay_close"
                    onClick={this.toggleOverlay}
                  >
                    <AiOutlineClose />
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className="overlay_container">
                    <Grid container spacing={0}>
                      <Grid item xs={12}>
                        <h2>Pre-Launch Offer</h2>
                      </Grid>
                      <Grid item xs={12}>
                        <p>
                          Sign up now for our Pre-Launch offer to get <em>LIFETIME ACCESS </em> 
                          for the one-off price of <em>$100 USD</em>. Limited to the first 
                          5000 subscribers, you better act fast.
                        </p>
                        <p>
                          As we progress to the official launch of EdgeForecaster, we are 
                          fine tuning the artificial intelligence models, testing the 
                          platform, optimising the data processing, and completing 
                          development of the mobile app.
                        </p>
                        <p>
                          We want to reward those who see the potential in a market tool 
                          like EdgeForecaster and those who want to be an early adopter 
                          of what will soon be essential for anyone wanting to actively 
                          trade. Our Pre-Launch offer gives complete, <em>LIFETIME ACCESS </em> 
                          to the EdgeForecaster platform, including all future development 
                          releases to anyone who signs up over the current period prior 
                          to our official launch. 
                        </p>
                        <p>
                          Click the <em>SignUp Now</em> button to secure your
                          lifetime market <em>EDGE</em>
                        </p>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className="overlay_container">
                    <Grid container spacing={0} style={{ height: '100%' }}>
                      <Grid item xs={12}>
                        <Grid container spacing={0}>
                          <Grid item xs={12}>
                            <div className="plansheading">
                              <h2>
                                {
                                  lifetimeplan.pricedetails['onetime_base']
                                    .product.name
                                }
                              </h2>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container spacing={0}>
                          <Grid item xs={8}>
                            <div className="plansdetail">
                              <ul className="planslist">
                                {lifetimeplan.planfeatures.map((item) => {
                                  return (
                                    <li key={item} className="planslistitem">
                                      {item}
                                    </li>
                                  )
                                })}
                              </ul>
                            </div>
                          </Grid>
                          <Grid item xs={4}>
                            <Grid container spacing={0}>
                              <Grid item xs={12}>
                                <input
                                  style={{ visibility: 'hidden' }}
                                  readOnly
                                  name="priceSelect"
                                  value={lifetimeplan.pricedetails.id}
                                />
                                <div className="planspricecontainer">
                                  $
                                  {(
                                    lifetimeplan.pricedetails['onetime_base']
                                      .unit_amount / 100
                                  ).toFixed(0)}
                                </div>
                              </Grid>
                              <Grid item xs={12}>
                                <div className="planspricefreq">
                                  {this.props.type === 'subscription'
                                    ? 'per month'
                                    : 'one off'}
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid container spacing={0}>
                          <Grid item xs={12}>
                            <Grid container spacing={0}>
                              <Grid item xs={1} md={6} lg={8} />
                              <Grid item xs={10} md={6} lg={4} style={{display:"flex", alignItems:"center", justifyContent:"center"}} >
                                <div className="planspricebutton">
                                  <div
                                    className="plans_button_main"
                                    onClick={() => {
                                      this.selectPlan(
                                        lifetimeplan.pricedetails[
                                          'onetime_base'
                                        ].id,
                                        lifetimeplan,
                                        'onetime',
                                      )
                                    }}
                                  >
                                    Signup Now
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      )
      detailsandswitch = (
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <div className="plans_subheading">
              Plans available for all levels of experience and trading activity:{' '}
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={0}>
              <Grid item xs={3} />
              <Grid item xs={3}>
                <FormHelperText className="priceSwitchLabel">
                  monthly
                </FormHelperText>
              </Grid>
              <Grid item xs={3}>
                <FreqSwitch
                  checked={this.state.annualprice}
                  onChange={this.togglePricing}
                />
              </Grid>
              <Grid item xs={3}>
                <FormHelperText className="priceSwitchLabel">
                  annually
                </FormHelperText>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )
    }

    return (
      <React.Fragment>
        <Seo pagename={pageName + ' ' + siteTitle} keywords={keywords} />
        <Layout data={this.props} pagewidth={pagewidth.matches}>
          {this.state.offeroverlay ? lifetime_overlay : <div />}

          <div className="page_wrapper generalpage_wrapper">
            <Grid container spacing={0}>
              <Grid item xs={12}>
                <h1 className="generalpage_majorheading">{pageName}</h1>
              </Grid>
              <Grid item xs={12} className="plans_subheadingcontainer">
                {detailsandswitch}
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  {products.map((product, key) => {
                    return (
                      <PlansContainer
                        key={key}
                        plandetails={product}
                        setLoading={this.setLoading}
                        selectPlan={this.selectPlan}
                        activeItem={this.state.activeItem}
                        activeItemAdj={this.activeItemAdj}
                        annualpricing={this.state.annualprice}
                        pagewidth={isMobile}
                      />
                    )
                  })}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Layout>
      </React.Fragment>
    )
  }
}

export default withCookies(PlansPage)
export const PlansPageQuery = graphql`
  query PlansPageQuery {
    site {
      siteMetadata {
        title
        description
        menuLinks {
          name
          link
          cl
        }
        keywords
      }
    }
    sitePage {
      pluginCreator {
        name
      }
    }
    prices: allStripePrice(
      filter: { active: { eq: true } }
      sort: { fields: [unit_amount] }
    ) {
      edges {
        node {
          product {
            id
            name
            statement_descriptor
          }
          id
          active
          currency
          unit_amount
          recurring {
            interval
          }
          type
          nickname
        }
      }
    }
  }
`
