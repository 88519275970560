const plansdata = {
  investor: {
    id: 'prod_L9D0UtHGQmeyYw',
    //id: 'prod_KgJ2JNi5uqGHKn',
    planName: 'investor',
    type: 'subscription',
    planfeatures: [
      'AI & ML Price Forecasts',
      'Real-time Trading Signals',
      'Quantitative Analytics Data',
      '10 Edge Securities',
      'Daily Timeframes',
    ],
    pricedetails: {},
  },
  trader: {
    id: 'prod_L9D0UiypyDcDEz',
    // id: 'prod_KgJ4eOPVDRTZxu',
    planName: 'trader',
    heading: 'Trader',
    type: 'subscription',
    planfeatures: [
      'AI & ML Price Forecasts',
      'Real-time Trading Signals',
      'Quantitative Analytics Data',
      '20 Edge Securities',
      'Daily & Hourly Timeframes',
    ],
    pricedetails: {},
  },
  professional: {
    id: 'prod_L9Czx5tgOVixtX',
    // id: 'prod_KgJ7kTqCpXSKHa',
    planName: 'professional',
    type: 'subscription',
    planfeatures: [
      'AI & ML Price Forecasts',
      'Real-time Trading Signals',
      'Quantitative Analytics Data',
      '30 Edge Securities',
      'Daily & Hourly Timeframes',
      'Automated Trading Facility',
    ],
    pricedetails: {},
  },
  lifetime: {
    id: 'prod_L9CzcLXDVaXhUA',
    // id: 'prod_KgJANceK0zUg1x',
    planName: 'lifetime',
    type: 'payment',
    planfeatures: [
      'Lifetime Complete Access',
      'AI & ML Price Forecasts',
      'Real-time Trading Signals',
      'Quantitative Analytics Data',
      '30 Edge Favourites',
      'Daily & Hourly Timeframes',
      'Automated Trading Facility',
      'All Future Feature Releases',
    ],
    pricedetails: {},
  },
}

export default plansdata
